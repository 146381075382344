.layout {
  display: flex;
  width: 100%;
  margin: auto;
}

.dashboard-container {
  width: 25%;
  background-color: #f8f9fa;
  transition: transform 0.3s ease-in-out; /* Smooth sliding transition */
  transform: translateX(0);
}

.main-content {
  width: 75%;
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
}

.sidebar-toggle-button {
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1000;
  background: none;
  border: none;
  /* color: #007bff; */
  cursor: pointer;
  box-shadow: none;
}

.sidebar-toggle-button.hidden {
  display: none;
}

.close-sidebar-btn {
  position: absolute;
  top: 70%;
  right: -31px; /* Adjust the position of the button outside the sidebar */
  transform: translateY(-50%);
  background: #201e43;
  border: none;
  border-radius: 0px 20px 20px 0px;
  color: white;
  cursor: pointer;
  padding: 40px 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}
@media (max-width: 1000px) {
  .dashboard-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px; /* Fixed width for smaller screens */
    max-width: 80%;
    background-color: #f8f9fa;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 999;
    transform: translateX(-100%);
  }

  .dashboard-container.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    /* width: 300px; */
    width: 100%;

    max-width: 80%;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 999;
    transform: translateX(0);
  }

  .sidebar-toggle-button {
    display: block;
    position: fixed;
    top: 70%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1000;
    background: #201e43;
    border: none;
    border-radius: 0 20px 20px 0;
    color: white;
    cursor: pointer;
    padding: 40px 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}
