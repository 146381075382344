.user-guidelines-container {
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
}

.user-guidelines-title {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 10px;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 0;
  box-sizing: border-box;
}

.step-item {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  min-width: 150px;
}

.arrow-container {
  display: flex;
  align-items: center;
}

.close-icon-userguide {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.overlay-img-div-userguideline {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
}

.overlay-img-div-userguideline img {
  cursor: pointer;
}

.overlay-haveAccount-userguide {
  margin-top: 20px;
  cursor: pointer;
}

.overlay-haveAccount-userguide:hover {
  text-decoration: underline;
}

.userguide-buttons-div {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.button-84 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .05);
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  height: 52px;
  padding: 0 32px;
  text-decoration: none;
  transition: all 150ms;
  user-select: none;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.button-84:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

.step-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto; 
  cursor: pointer;
  margin-top: 10px;
}

.circle-progress {
  height: 50px;
  width: 50px;
}

.step-title-span {
  font-weight: bold;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .steps-container {
      gap: 10px;
  }
  .step-item {
      min-width: 120px;
  }

  .user-guidelines-title {
    display: none;
    font-size: 22px;
    text-align: center;
  }

  .user-guidelines-container {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .steps-container {
      gap: 30px;
  }
}
