/* Container */
.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
  background-color: #eff3f9;
  height: 100vh;
}

.home-page-main-content {
  max-width: 900px;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home-page-main-content::-webkit-scrollbar {
  display: none;
}

.investment-summary-h1 {
  font-size: 27px;
  font-weight: 600;
  color: black;
  margin-top: 10px;
  text-align: center;
}

.investment-summary-span {
  font-weight: 200;
}

.homepage-top-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #e0f4f6, #9acbd0);
  padding: 15px;
  border-radius: 16px;
  margin-bottom: 0px;
  gap: 10px;
}

.homepage-top-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
}

.homepage-top-p {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.homepage-top-button {
  background-color: #508c9b;
  font-weight: 700;
  color: #fff;
  padding: 18px 28px;
  border-radius: 18px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Dashboard */
.home-page-dashboard {
  width: 100%;
  max-width: 300px;
  background: #0f3460;
  color: #fff;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1);
  animation: slideInLeft 0.5s;
}

.home-page-main-content p {
  font-size: 16px;
  color: #201e43;
  text-align: center;
}

.home-page-main-content h2 {
  font-size: 1em;
}

/* Default font size */
.homepage-portfolio-details {
  font-size: 1.1em; /* Default font size */
  line-height: 1.5;
  margin-top: 40px;
  height: 100%;
}

.user-info-container img {
  margin-bottom: -10px;
}

.homepage-portfolio-details-bottom-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
  padding: 0px 15px 0px 15px;
}

/* Responsive font size for small screens */
@media (max-width: 600px) {
  .homepage-portfolio-details {
    font-size: 0.9em; /* Adjust font size for smaller screens */
    line-height: 1.4; /* Adjust line height */
  }

  .homepage-portfolio-details-bottom-div {
    flex-direction: column;
  }
}

/* Earnings */
.home-page-earnings {
  background: #1a1a2e;
  padding: 25px; /* Padding for emphasis */
  border-radius: 15px;
  margin: 20px 0;
  text-align: center;
  transition: transform 0.3s;
  color: #fff;
}

.home-page-earnings:hover {
  transform: scale(1.05); /* Gentle scale for interactivity */
}

/* Feature Cards */
.home-page-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 25px;
  margin-top: 40px;
}

.home-page-feature {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 300px;
}

.home-page-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Feature Headings */
.home-page-feature h2 {
  color: #2575fc;
  text-align: center;
  font-size: 1.5em; /* Classic-sized heading */
}

/* Feature Text */
.home-page-feature p {
  font-size: 1.15em;
  color: #555;
  text-align: center;
}

/* Plan-Specific User Info Styling */
.homepage-top-div.Gold {
  background-image: linear-gradient(to bottom, #f7b86cb9, #fde477b9);
  color: #fff;
}

/* Credential Instructions */
.credential-instructions {
  background-color: #f9f9f9;
  border: 1px solid #dcdfe6;
  border-radius: 15px;
  padding: 10px;
  color: #333;
  font-size: 1.05em; /* Larger text */
}

.credential-instructions a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

/* Highlight Text */
.highlight {
  font-weight: bold;
  color: #2575fc;
}

.profit {
  color: #28a745;
}

.loss {
  color: #dc3545;
}

.credential-instructions {
  font-family: Arial, sans-serif;
  margin: 2px;
}

.download-instructions {
  margin: 1px 0;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  padding: 0;
}

.download-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 5px 0;
}

.download-button {
  display: inline-block;
  padding: 20px 26px;
  background-color: #9acbd0;
  color: black;
  text-decoration: none;
  border-radius: 13px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #302e56;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  cursor: pointer;
}

.login-failed-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  overflow: hidden;
}

.home-page-user-guidelines {
  width: 100%;
  overflow: hidden; /* Prevent extra scrolling */
}

/* feedback  */
.feedback-btn-div {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #00c3b9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #009e94;
}

.feedback-inside-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #00c3b9;
  width: 30px;
  height: 30px;
}

.homepage-bottom-p-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  gap: 8px;
  margin-bottom: 10px;
}

.user-info-container-2 {
  display: none;
}

.loading-spinner {
  height: auto;
  text-align: center;
}

.investment-summary {
  width: 50%;
}

.homepage-bottom-image1-div {
  display: none;
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 450px) {
  .home-page-main-content h1 {
    text-align: center;
  }

  .home-page-main-content h2 {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .home-page-main-content {
    padding: 1px;
  }

  .homepage-top-div {
    padding: 15px 0;
    border-radius: 0;
  }

  .homepage-portfolio-details-bottom-div {
    flex-direction: column;
  }

  .homepage-portfolio-details {
    margin-top: 25px;
  }

  .user-info-container {
    display: none;
  }

  .user-info-container-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .investment-summary-h1 {
    font-size: 20px;
    margin-top: 0px;
    padding-bottom: 0;
  }

  .homepage-top-button-div {
    margin-top: 0;
  }

  .download-buttons {
    flex-direction: row;
    padding: 2px;
  }

  .credential-instructions {
    margin: 1px;
  }

  .download-instructions-div {
    margin-top: 0px;
  }

  .download-instructions-div p {
    margin: 0;
    font-size: 15px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .homepage-bottom-p-div {
    font-size: 15px;
    margin-top: 8px;
  }

  .homepage-top-button {
    margin-top: 10px;
    padding: 14px 28px;
  }

  .investment-summary {
    width: 100%;
  }

  .homepage-bottom-image-div {
    display: none;
  }

  .homepage-bottom-image1-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-top-p {
    margin-bottom: 0;
  }

  .download-button {
    padding: 10px 18px;
  }
}

/* Responsive */
@media (max-width: 1000px) {
  .home-page-feature {
    max-width: 100%;
  }
}