.profile-page-container-div {
  height: 100%;
  background-color: #EFF3F9;
  padding: 30px 0 0 0;
}

.profile-page {
  position: relative;
  max-width: 750px;
  margin: auto;
  background-color: #F9F9F9;
  padding: 25px 8px;
  border-radius: 8px;
}

.profile-info p {
  margin-bottom: 0px;
}

.creds-form-edit {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invalid-dropdown {
  border-color: red;
  background-color: #ffe6e6;
}

.save-creds-btn,
.edit-creds-btn {
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.save-creds-btn:hover,
.edit-creds-btn:hover {
  background-color: #0056b3;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 30px; /* Adjust this value as needed */
}

.password-input span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.downloadables {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download img {
  width: 50px;
}

.download a {
  text-decoration: none;
  color: #007bff;
}

.close {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close:hover {
  background: #0056b3;
}

.feedback-btn-div {
  position: fixed;
  z-index: 100;
}

@media (max-width: 480px) {
  .feedback-btn-div {
    right: 10px;
    bottom: 10px;
  }
}

.feedback-btn {
  display: flex;
  align-items: center;
  background-color: #00c3b9;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #009e94;
}

.feedback-inside-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #00c3b9;
  width: 30px;
  height: 30px;
}

.feedback-a-tag {
  text-decoration: none;
}

.edit-creds-btns {
  display: flex;
  gap: 10px;
}

.save-creds-btn,
.edit-creds-btn-logout {
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.save-creds-btn:hover,
.edit-creds-btn-logout:hover {
  background-color: #0056b3;
}

.edit-creds-btn-logout-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.referral-link-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.profile-referral-link-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-decoration: none;
  color: black;
  background-color: #9ACBD0 !important;
  padding: 16px 26px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: center;
}

.profile-referral-link-section:hover {
  color: black;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.profile-referral-link-section div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.profile-referral-link-section svg {
  font-size: 12px;
}

.profile-referral-link-section span {
  font-size: 15px;
}

@media (min-width: 1000px) {
  .edit-creds-btn-logout {
    display: none;
  }
}

.top-title-content-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.profilepage-edit-creds-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.profilepage-bottom-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.profilepage-bottom-image img {
  width: 650px;
}

.profile-page-form-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  max-width: 600px;
  margin: auto;
}

.profile-page-form-div label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.profile-page-form-div input,
.profile-page-form-div select {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s;
  border-radius: 18px;
  background-color: #EEEEEE;
}

.profile-page-form-div input:focus,
.profile-page-form-div select:focus {
  border-color: #007bff;
  outline: none;
}

.password-input {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input input {
  flex: 1;
  padding-right: 40px;
}

.password-input span {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.invalid-dropdown {
  border: 1px solid red;
}

.profile-page-edit-creds-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profile-edit-creds-btn {
  padding: 15px 30px;
  background-color: #9ACBD0;
  font-weight: 600;
  color: #201E43;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.profile-edit-creds-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 600px) {
  .profile-page-form-div {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .profile-page {
    margin: 9px;
  }

  .profilepage-bottom-image img {
    width: 350px;
  }

  .top-title-content-div {
    flex-direction: column;
    gap: 1px;
  }

  .profile-info p {
    text-align: center;
  }

  .referral-link-div {
    gap: 10px 6px;
  }

  .profile-page-edit-creds-btns {
    flex-direction: column;
    width: 100%;
  }

  .profile-edit-creds-btn {
    width: 80%;
    padding: 12px 12px;
  }

  .profile-page-container-div {
    padding: 0;
    overflow: hidden;
  }
}