.register-page-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;
  height: 92vh;
  background-color: #EFF3F9;
}

.register-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: #F9F9F9;
  /* padding: 30px 50px 30px 50px; */
  padding: 50px;
  border-radius: 18px;
  max-width: 480px;
  width: 100%;
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.register-form-container {
  width: 100%;
}

.register-heading h2{
  color: #201E43;
}

.form-group {
  position: relative;
}

.form-label-register {
  display: flex;
  justify-content: start;
  font-size: 15px;
  font-weight: 600;
  margin: 5px;
  color: #201E43;
}

.register-form-inputOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.register-form-inputOne input {
  padding: 10px 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 18px;
  transition: border-color 0.3s;
  flex: 1;
}

.register-sendotp-button {
  background-color: #EEEEEE;
  font-weight: 600;
  font-size: 14px;
  border-radius: 14px;
  padding: 8px 10px;
  white-space: nowrap;
}

.register-form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.register-form-input input {
  padding: 10px 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 18px;
  transition: border-color 0.3s;
  width: 100%;
}

.register-form-input input:focus {
  border-color: #201E43;
  outline: none;
}

.register-label-password {
  margin-top: 20px;
}

.register-password-toggle-icon {
  position: absolute;
  right: 20px;
  top: 54%;
  transform: translateY(-56%);
  cursor: pointer;
  color: #555;
}

.register-password-toggle-icon:hover {
  color: #007bff;
}

.register-message-item {
  margin: 0;
  font-size: 15px;
  color: #555;
}

.register-message-item {
  color: #201E43;
  text-decoration: none;
  font-weight: bold;
}

.register-message-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.register-button-buttom {
  width: 100%;
  padding: 12px 20px;
  background: #9ACBD0;
  color: #000000;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.register-button-buttom:hover {
  background: #201E43;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
}

.register-button-buttom:active {
  background: #004085;
  box-shadow: 0 3px 6px rgba(0, 64, 133, 0.2);
  transform: translateY(2px);
}

.register-right {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.register-right img {
  object-fit: cover;
  overflow: hidden;
  width: 450px;
}

.remember-me-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
}

.remember-me-container input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.register-remember-me-label {
  margin-left: 5px;
  font-size: 15px;
  color: #201E43;
  font-weight: 400;
}

.register-already-account-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.register-already-account-div p {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.register-already-account-span {
  font-weight: 700;
}

.register-already-account-span:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .register-page-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .register-right img {
    width: 150px;
    margin-top: 30px;
  }

  .register-left {
    width: 90%;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .register-left {
    padding: 15px;
    width: 95%;
  }

  .register-form-input input {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .register-button-buttom {
    font-size: 1rem;
    padding: 12px;
  }

  .register-container {
    padding: 15px;
  }

  .register-page-container {
    gap: 1px;
  }

  .register-message-item {
    font-size: 13px;
  }

  .register-password-toggle-icon {
    top: 55%;
  }
}

@media (max-width: 480px) {
  .register-left {
    width: 100%;
    padding: 10px;
  }

  .register-form-container {
    width: 100%;
  }

  .register-form-input input {
    font-size: 0.9rem;
    padding: 8px 20px;
  }

  .register-button-buttom {
    font-size: 0.9rem;
    padding: 10px;
  }

  .register-right img {
    width: 150px;
    margin-top: 20px;
  }

  .register-page-container {
    gap: 0px;
  }
}
