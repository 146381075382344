.login-page-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;
  height: 92vh;
  background-color: #EFF3F9;
}

.login-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: #F9F9F9;
  /* padding: 30px 50px 30px 50px; */
  padding: 50px;
  border-radius: 18px;
  max-width: 480px;
  width: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.login-form-container {
  width: 100%;
}

.login-heading h2{
  color: #201E43;
}

.form-group {
  position: relative;
}

.form-label-login {
  display: flex;
  justify-content: start;
  font-size: 15px;
  font-weight: 600;
  margin: 5px;
  color: #201E43;
}

.login-form-input {
  position: relative;
  width: 100%;
}

.login-form-input input {
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 18px;
  transition: border-color 0.3s;
}

.login-form-input input:focus {
  border-color: #201E43;
  outline: none;
}

.login-label-password {
  margin-top: 20px;
}

.login-password-toggle-icon {
  position: absolute;
  right: 25px;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
}

.login-password-toggle-icon:hover {
  color: #007bff;
}

.login-message-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:20px 10px 0px 10px;
}

.login-message-item {
  margin: 0;
  font-size: 15px;
  color: #555;
}

.login-message-item {
  color: #201E43;
  text-decoration: none;
  font-weight: bold;
}

.login-message-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.login-button-buttom {
  width: 100%;
  padding: 12px 20px;
  background: #9ACBD0;
  color: #000000;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.login-button-buttom:hover {
  background: #201E43;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
}

.login-button-buttom:active {
  background: #004085;
  box-shadow: 0 3px 6px rgba(0, 64, 133, 0.2);
  transform: translateY(2px);
}

.login-right {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.login-right img {
  object-fit: cover;
  overflow: hidden;
  width: 450px;
}

.remember-me-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
}

.remember-me-container input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.login-remember-me-label {
  margin-left: 5px;
  font-size: 15px;
  color: #201E43;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .login-page-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .login-right img {
    width: 400px;
    margin-top: 30px;
  }

  .login-left {
    width: 90%;
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .login-left {
    padding: 15px;
    width: 95%;
  }

  .login-form-input input {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .login-button-buttom {
    font-size: 1rem;
    padding: 12px;
  }

  .login-container {
    padding: 15px;
  }

  .login-right img {
    width: 300px;
  }

  .login-page-container {
    gap: 30px;
  }

  .login-message-item {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .login-left {
    width: 100%;
    padding: 10px;
  }

  .login-form-container {
    width: 100%;
  }

  .login-form-input input {
    font-size: 0.9rem;
    padding: 8px 20px;
  }

  .login-button-buttom {
    font-size: 0.9rem;
    padding: 10px;
  }

  .login-right img {
    width: 240px;
    margin-top: 20px;
  }

  .login-page-container {
    gap: 0px;
  }
}
