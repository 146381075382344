.forgot-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
  width: 100%;
  background-color: #EFF3F9;
}

.forgot-password-container {
  background-color: #F9F9F9;
  padding: 2.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 550px;
  width: 100%;
}

.forgetgape-goback-link {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 0;
}

.forgetgape-goback-link a {
  color: #201E43;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}

.forgetgape-goback-link a:hover {
  color: #201E43;
  text-decoration: underline;
}

.forgotpassword-heading-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgotpassword-heading {
  color: #333;
}

.form-group {
  margin-top: 10px;
}

.form-group label {
  display: block;
  font-size: 18px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
  margin-left: 5px;
}

.forgetpage-form-input {
  display: flex;
  align-items: center;
}

.forgetpage-form-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 15px;
  margin-right: 5px;
}

.forgetpage-form-input button {
  width: 30%;
  padding: 10px 15px;
  background-color: #9ACBD0;
  color: black;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.forgetpage-form-input button:disabled {
  background-color: #ccc;
}

@media (max-width: 480px) {
  .forgot-password-container {
    padding: 30px 10px;
    max-width: 95%;
  }

  .form-group label {
    margin-bottom: 0;
  }
  
  .forgetpage-form-input button {
    padding: 10px 9px;
    width: 35%;
    font-size: 13px;
  }
}
