/* Container */
.faq-page-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
  color: #333;
  transition: all 0.3s ease-in-out;
}

/* Heading */
.faq-page-heading {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 20px;
  color: #222;
}

/* Tabs */
.faq-page-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 22px;
  flex-wrap: wrap;
}

.faq-page-tab {
  padding: 10px 20px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #666666;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-page-tab.active {
  color: #000000;
}

.faq-page-tab:hover {
  cursor: pointer;
}

.faq-page-tab-divider {
  margin: 0;
  padding: 0;
  height: 5px;
  background-color: #000000;
  border: 1px solid black;
  border-radius: 100px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}

.faq-page-tab-divider.active {
  opacity: 1;
}

/* Category Section */
.faq-page-category {
  padding: 0 10px 0px 10px;
}

/* Items */
.faq-page-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-page-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #201E43;
}

.faq-page-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.faq-page-toggle.open {
  transform: rotate(180deg);
  color: #201E43;
}

/* Answers */
.faq-page-answer {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  font-size: 0.9rem;
  color: #555;
}

.faq-page-answer.visible {
  max-height: 500px;
  opacity: 1;
  margin-top: 10px;
}

/* Contact Section */
.faq-page-contact {
  text-align: center;
  margin-top: 5px;
  font-size: 1.1rem;
}

.faq-page-contact p {
  margin: 0;
  color: #201E43;
  font-size: 18px;
  font-weight: 600;
}

.faq-page-contact-button {
  display: inline-block;
  background-color: #9ACBD0;
  color: black;
  padding: 15px 45px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.faq-page-contact-button:hover {
  background-color: #201E43;
  color: white;
  cursor: pointer;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .faq-page-heading {
    font-size: 2rem;
  }

  .faq-page-tabs {
    gap: 8px;
  }

  .faq-page-tab {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .faq-page-question {
    font-size: 0.9rem;
  }

  .faq-page-toggle {
    font-size: 1.2rem;
  }

  .faq-page-answer {
    font-size: 0.8rem;
  }

  .faq-page-contact {
    font-size: 1rem;
  }

  .faq-page-contact-button {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .faq-page-tabs {
    margin-bottom: 10px;
  }
}
