/* 305 and 29 line number */
.navbar {
  font-size: var(--text-font-size);
  /* min-width: 320px; */
  background-color: var(--color-bg-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  z-index: 9;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-bg-default);
  /* box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1); */
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
}

.header-left {
  width: 17%;
  /* width: 15%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-logo {
  width: 35px; /* Adjust the width as needed */
  border-radius: 5px;
  color: #333;
  height: auto;
  text-decoration: none;
  margin: auto;
}

.header-right {
  width: 68%;
  display: flex;
  justify-content: right;
  margin-right: 0;
  align-items: center;
  /* margin-top: 10px; */
  margin-bottom: -25px;
}

/* .logo {
  height: 50px; Adjust as needed
} */

/* .silver-shadow {
  box-shadow: 0 0px 20px silver;
  
}

.gold-shadow {
  box-shadow: 0 0px 20px gold;
} */

.market-data {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.market-item {
  font-size: 0.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.trading-symbol {
  font-weight: 600;
}

.ltp {
  margin-left: 0.25rem;
  font-weight: 400;
}

.market-item .change {
  font-size: 0.625rem;
  margin-left: 0.25rem;
}

.positive {
  color: var(--positive-change);
}

.negative {
  color: var(--negative-change);
}

.title {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-left: 1rem;
  font-weight: 700;
}

.navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
}

.navLinks li {
  margin: 0 1.5rem;
}

.navLink {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #000;
}

.navLink.active-link a {
  color: #000;
  font-weight: 700;
}

.menu-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.menu-button:hover {
  color: #ddd;
}

/* Style the Logout button to match the nav links */
.navLink button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  /* margin-top: 50%; */
  transition: color 0.3s ease, background-color 0.3s ease;
  /* padding: 0.5rem 1rem; */
  font-family: inherit; /* Ensure the font matches other nav links */
}

.navLink button:hover {
  color: #000; /* Same hover effect as nav links */
  background-color: rgba(
    0,
    0,
    0,
    0.05
  ); /* Optional: Add a subtle background on hover */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.navLink button:focus {
  outline: none; /* Remove default button focus outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for focus */
}

.profile-icon {
  position: relative;
  width: 40px;
  height: 30px;
}

.menu-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu-button:hover {
  color: #000;
}

.dropdown {
  position: absolute;
  top: 31%;
  right: 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 1000;
  width: 123px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown.open {
  opacity: 1;
  transform: translateY(0);
  display: block;
}

.dropdown-item-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  padding: 12px;
}

.dropdown-item-navbar:hover {
  color: #000;
  background-color: #ddd;
}
.profile-icon,
.menu-button {
  margin: 0;
  padding: 0;
}

/* Default Navbar Style */
.navbar {
  font-size: var(--text-font-size);
  /* min-width: 320px; */

  background-color: var(--color-bg-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  z-index: 9;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-bg-default);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 30px;
  width: 100%;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-logo {
  width: 150px;
  border-radius: 5px;
  height: auto;
  margin: auto;
}

.header-right {
  /* width: 68%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -10px;
  margin-right: 20px;
  width: 100%;
  gap: 50px;
}

.navLinks {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  gap: 10px;
  padding: 6px;
}

.navLink:hover {
  color: #000;
}

.navLink.profile,
.navLink.logout {
  display: none;
}

.no-auth-login {
  margin-right: 50px;
  font-weight: normal;
  font-size: medium;
}

.logo-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo-text-h2-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-size: large;
  text-align: center;
  gap: 5px;
  font-weight: bold;
  color: #0c3934;
}

.logo-text-h2-div p {
  margin: 0;
  font-size: inherit;
}

.no-auth-login p {
  font-size: 16px;
}

.no-auth-login p:hover {
  cursor: pointer;
}

/* Media Queries for Mobile */
@media (max-width: 1000px) {
  .navLinks {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    transition: visibility 0s 0.3s, opacity 0.3s ease, transform 0.3s ease;
    overflow-y: scroll;
  }

  .line-navbar-dashboard {
    display: none !important;
  }

  .navLinks li {
    margin: 0;
  }

  .mobile-line-remove {
    display: none;
  }

  .navLinks {
    gap: 0px;
  }

  .navLinks {
    padding: 0;
  }

  .navLinks.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: visibility 0s, opacity 0.3s ease, transform 0.3s ease;
  }

  .navLink:last-child {
    border-bottom: none;
  }

  .navLink a {
    color: #333;
    text-decoration: none;
    display: block;
    font-size: 13px;
  }

  .navLink a:hover {
    color: #000;
  }

  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 20;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .navbar-menu span {
    height: 0.25rem;
    width: 100%;
    background-color: #333;
    border-radius: 0.2rem;
    transition: all 0.3s ease-in-out;
  }

  .navbar-menu.open span:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    transform-origin: center;
  }

  .navbar-menu.open span:nth-child(2) {
    opacity: 0;
  }

  .navbar-menu.open span:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    transform-origin: center;
  }

  .profile-icon {
    display: none;
  }

  .navLink.profile,
  .navLink.logout {
    display: block;
  }

  .no-auth-login {
    margin-right: 10px;
  }

  .logo-text-h2-div {
    font-size: medium;
  }

  .navbar-logo {
    width: 130px;
  }

  .navLink {
    padding: 8px !important;
  }

  .navLinks li {
    width: 100%;
  }
}

.profile-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.menu-button p {
  margin: 0;
  font-size: 16px;
}

.navLink {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 15px;
  position: relative;
  display: inline-block;
  padding: 0;
}

.line-navbar-dashboard {
  display: none;
  height: 3px;
  background-color: #000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  transition: width 0.3s ease;
  border-radius: 20px;
}

.line-navbar-dashboard.active {
  display: block;
}
