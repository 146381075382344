/* ProductPage.css */
body {
  font-family: "Roboto", sans-serif;
  background-color: #eff3f9;
  margin: 0;
  padding: 0;
  color: #333;
}

.toggle-container {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  gap: 10px;
  color: #201e43;
}

.toggle-container span {
  font-size: 18px;
}

.toggle-switch {
  margin: 0 10px;
  position: relative;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0a4d8a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.products-container {
  gap: 20px;
  max-width: 1300px;
  width: 90%;
  margin: auto;
  justify-content: center;
}

.products-header {
  text-align: center;
}

.products-header h1 {
  font-size: 36px;
  color: #201e43;
  margin: 18px 0 5px 0;
  font-weight: bold;
}

.products-header p {
  color: #201e43;
  font-size: 22px;
  font-weight: lighter;
}

.price-card-desc {
  color: #201e43 !important;
  width: 100%;
  height: 10%;
}

.card-heading {
  height: 8%;
}

.card-heading h3 {
  color: #201e43 !important;
  font-weight: bold;
}

.features-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.features {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  margin: 10px 0 5px 0;
  height: 50%;
}

.features h4 {
  text-align: start;
  font-size: 1.1em;
  color: #333;
  margin: 10px 0;
  font-weight: bold;
}

/* this should be at start */
.features-heading {
  display: flex;
  align-items: start;
  justify-content: start;
}

.benefits-section,
.strategy-section {
  background-color: #fff;
  border-radius: 12px;
  /* padding: 30px; */
  margin-bottom: 30px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.benefits-section h2,
.strategy-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #007bff;
}

.benefits-section p,
.strategy-section p {
  line-height: 1.6;
  font-size: 1.1em;
}

.benefits-section ul {
  list-style: disc;
  margin-left: 20px;
  line-height: 1.6;
}

blockquote {
  margin: 20px 0;
  font-style: italic;
  color: #555;
  border-left: 4px solid #007bff;
  padding-left: 15px;
}

button.pricing-link {
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
  /* position: absolute;
     bottom: 0;  */
}

button.pricing-link:hover {
  background-color: #0056b3;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-heading {
  margin-bottom: 30px;
}

.pricing-card-container-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 1em;
  width: 100%;
}

.pricing-card {
  border-radius: 12px;
  padding: 30px;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: #201e43;
}

.pricing-card-free {
  background: linear-gradient(to bottom, #e0f4f6, #9acbd0ac);
}

.pricing-card-gold {
  background: linear-gradient(to bottom, #fff0ed, #fecc048f);
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-card h3 {
  color: #0a4d8a;
  /* margin: 10px 0 10px; */
  text-align: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container label {
  font-size: 1em;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  font-size: 1em;
  text-align: left;
}

.pricing-card ul li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.pricing-card ul li.checked::before {
  content: "✅";
  color: green;
  margin-right: 10px;
}

.pricing-card ul li.unchecked::before {
  content: "❌";
  color: red;
  margin-right: 10px;
}

button.purchase-button {
  width: 266px;
  height: 66px;
  background-color: #201e43;
  color: white;
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
}

.price {
  font-size: 1.2em;
  color: #201e43;
  font-weight: bold;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5vh;
  width: 100%;
}

button.purchase-button:hover {
  background-color: #201e43f0;
}

.payment-form {
  background: #201e43;
  color: white;
  text-align: center;
}

.payment-form-h3 {
  font-size: 1.5em;
  color: white !important;
  font-weight: bold;
  margin-bottom: 20px;
}

.payment-form p {
  font-size: 13px;
}

.payment-form .qr-code {
  max-width: 150px;
  margin-bottom: 20px;
}

.payment-form input {
  width: calc(100% - 40px);
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: white;
  border-radius: 10px !important;
  padding-left: 10px !important;
  font-size: 1em;
}

.payment-form button.submit-payment {
  margin: 2px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.payment-form button.submit-payment:hover {
  background-color: #0056b3;
  color: white;
}

@media (max-width: 1000px) {
  .pricing-card-container-div {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    max-width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-support {
  font-weight: bold;
}

.contact-support:hover {
  text-decoration: underline;
  cursor: pointer;
}

.popup {
  background: #201e43;
  color: white;
  border-radius: 12px;
  padding: 10px;
  width: 500px;
  max-width: 80%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 2em;
  cursor: pointer;
}

.payment-form h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #007bff;
}

.payment-form .qr-code {
  max-width: 150px;
  margin-bottom: 20px;
}

.payment-form input {
  width: calc(100% - 40px);
  margin: 5px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1em;
}

.payment-form button.submit-payment {
  margin: 2px auto;
  padding: 10px 20px;
  background-color: white;
  color: #000000;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.payment-form button.submit-payment:hover {
  background-color: #0056b3;
}

.original-price {
  color: #201e43;
  font-weight: bold;
  font-size: 1.2em;
}

.discounted-price {
  color: #201e43;
  font-weight: bold;
  font-size: 1.2em;
  margin-left: 10px;
}

.banner {
  max-width: 90%;
  padding: 15px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: pulsate 3s infinite;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
}

@keyframes pulsate {
  0% {
    background-color: #ff7f50;
  }

  /* Coral */
  50% {
    background-color: #ffdf00;
  }

  /* Yellow */
  100% {
    background-color: #ff7f50;
  }
}

/* ------------ coupon  */

.coupon-section {
  margin: 20px 0 5px 0;
  padding: 10px;
}

.coupon-section form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.coupon-section input[type="text"] {
  padding: 10px;
  width: 20%;
  border: 1px solid #ddd;
  border-radius: 18px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
  padding-left: 30px;
}

.coupon-section input[type="text"]:focus {
  border-color: #007bff;
}

.coupon-section button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  background-color: #eff3f9;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 18px;
  color: #112d33;
  transition: background-color 0.3s;
  border: 1px solid #112d33;
}

.coupon-section button:hover {
  background-color: #112d33;
  color: white;
}

.coupon-section p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #28a745;
  background-color: #e9f7e9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #28a745;
}

.coupon-section p {
  font-size: 16px;
  color: #007bff;
}

@media (max-width: 1000px) {
  .coupon-section input[type="text"] {
    width: 40%;
  }

  .coupon-section button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .coupon-section input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .coupon-section button {
    padding: 12px 18px;
    font-size: 16px;
    width: 100%;
  }

  .coupon-section form {
    flex-direction: column;
    align-items: center;
  }

  .coupon-section button {
    margin-top: 10px;
  }

  .original-price {
    font-size: 1em;
  }

  .popup {
    width: 300px;
    max-width: 100%;
    height: 95vh;
  }

  button.purchase-button {
    width: 200px;
  }

  .pricing-card {
    padding: 20px;
    margin: 1px;
  }

  .products-header h1 {
    font-size: 22px;
  }

  .products-header p {
    font-size: 16px;
  }
}

/* Overlay and Popup */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #201e43;
  color: white;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
}

/* Payment Form */
.payment-form {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 2px;
}

.payment-form h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: white;
}

.payment-form p {
  font-size: 0.9em;
  margin-bottom: 1px;
}

.payment-form .qr-code {
  max-width: 150px;
  margin: 0 auto 10px;
}

.payment-form input {
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
  background-color: transparent;
  color: white;
}

.payment-form button.submit-payment {
  width: 70%;
  padding: 10px;
  background-color: white;
  color: #000000;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.payment-form button.submit-payment:hover {
  background-color: #0056b3;
  color: white;
}

.payment-form-p {
  margin-top: 10px;
}

.limited-time-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -15px;
  width: 100%;
}

.limited-time-div span {
  text-align: center;
  color: red;
  font-weight: bold;
}

@media (max-width: 768px) {
  .payment-form {
    gap: 0px;
    padding: 5px;
  }

  .popup {
    width: 90vh;
    height: auto;
    padding: 10px;
  }

  .payment-form-hr {
    margin-top: 0px;
    margin-bottom: 6px;
  }

  .payment-form h3 {
    font-size: 1.1em;
    padding: 2px;
  }

  .payment-form p {
    font-size: 0.8em;
    padding-bottom: 0px;
  }

  .payment-form input {
    font-size: 0.9em;

  }

  .payment-form button.submit-payment {
    font-size: 0.9em;
  }
}
